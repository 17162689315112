<template>
  <div>
    <v-row class="mt-12">
      <v-col cols="12">
        {{ $t("PROJECT.AUDIT.NO_CONTENT_MESSAGE") }}
      </v-col>
    </v-row>
    <v-row dense class="mb-6">
      <v-spacer />
      <v-col cols="6">
        <base-file-upload
          :on-file-change="onFileChange"
          :text="$t('PROJECT.AUDIT.IMPORT_CONTENT_FROM_FILE')"
          :disabled="isLoading"
          :is-uploading="isUploading"
          :error-message="errorMessage"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </v-col>
      <v-col cols="1" class="text-left">
        <v-btn
          color="success"
          :disabled="uploadDisabled"
          outlined
          x-large
          height="56"
          @click="startUploading"
          >UPLOAD</v-btn
        >
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
import BaseFileUpload from "@/components/BaseFileUpload";
import { mapActions } from "vuex";
export default {
  name: "ConfigUpload",
  components: { BaseFileUpload },
  data() {
    return {
      isLoading: false,
      isUploading: false,
      errorMessage: null,
      uploadDisabled: true,
      formData: null
    };
  },
  methods: {
    ...mapActions({
      uploadContentFile: "api/topicModeling/uploadContentFile"
    }),
    onFileChange(file) {
      this.errorMessage = null;

      if (!file) {
        return;
      }
      this.formData = new FormData();
      this.formData.append("excel", file);
      this.uploadDisabled = false;
    },
    startUploading() {
      this.isUploading = true;

      this.uploadContentFile({
        projectId: this.$route.params.projectId,
        file: this.formData
      })
        .then(() => {
          this.formData = null;
          this.uploadDisabled = true;
          this.$emit("file-present", true);
          this.$notifier.notify({
            message: this.$t("LANGUAGE_AUDIT.FILE_IMPORTED")
          });
        })
        .catch(error => {
          this.errorMessage = error.response.data.error;
          this.$notifier.notify({
            message: this.errorMessage,
            color: "error"
          });
        })
        .finally(() => {
          this.isUploading = false;
          this.$router.push({ name: "LanguageAudit" });
        });
    }
  }
};
</script>
