<template>
  <v-container>
    <div class="text-left">
      <div class="text-h6 title">
        {{ $t("LANGUAGE_AUDIT.SETTINGS.MODAL.TITLE") }}
      </div>
      <div class="caption title font-weight-light mb-4">
        {{ $t("LANGUAGE_AUDIT.SETTINGS.MODAL.DESCRIPTION") }}
      </div>
    </div>
    <v-col class="dark-theme" v-if="form !== null">
      <v-col>
        <v-row>
          <v-text-field
            @keypress.enter.prevent
            dark
            filled
            v-model="form.numberOfTopics"
            :hint="$t('COMMON.MAX_10')"
            :label="$t('COMMON.NUMBER_OF_TOPICS')"
            :rules="numberRules_1"
            :disabled="isLoading"
            :value="5"
          />
        </v-row>
        <v-row>
          <v-text-field
            @keypress.enter.prevent
            dark
            filled
            v-model="form.numberOfTopWords"
            :disabled="isLoading"
            :hint="$t('COMMON.MAX_50')"
            :label="$t('COMMON.NUMBER_OF_TOP_WORDS')"
            :rules="numberRules_2"
            :value="10"
          />
        </v-row>
        <v-row>
          <v-text-field
            @keypress.enter.prevent
            dark
            filled
            v-model="form.numberOfTopDocuments"
            :disabled="isLoading"
            :hint="$t('COMMON.MAX_20')"
            :label="$t('COMMON.NUMBER_OF_TOP_DOCS')"
            :rules="numberRules_3"
            :value="20"
          />
        </v-row>
      </v-col>
      <v-row>
        <v-col>
          <v-select
            @input="$refs.select.blur()"
            dark
            filled
            ref="select"
            v-model="form.dictionary"
            :disabled="isLoading"
            :hint="$t('LANGUAGE_AUDIT.SETTINGS.MODAL.DICTIONARY.HINT')"
            :items="countryDictionary"
            :label="$t('COMMON.SELECT_DICTIONARY')"
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
            :validate-on-blur="true"
          />
          <div
            class="caption title font-weight-light font-italic text-left text--white"
          >
            {{ $t("LANGUAGE_AUDIT.SETTINGS.MODAL.DICTIONARY.HINT") }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="caption title text-left text--white">
            {{ $t("LANGUAGE_AUDIT.SETTINGS.MODAL.CUSTOM_STOP_WORDS") }}
          </div>
          <v-text-field
            @keypress.enter.prevent="addStopWord"
            autocomplete="off"
            dark
            filled
            v-model="stopWord"
            :disabled="isLoading"
            :label="$t('COMMON.ADD_STOP_WORD')"
          />
          <v-row class="chips-list ml-2 mr-2">
            <v-chip
              @click:close="removeStopWord(index)"
              close
              color="primary"
              v-for="(stopWord, index) in form.customStopwords"
              :key="index"
            >
              {{ stopWord }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
import clone from "clone";
import { mapGetters } from "vuex";
import { topicModelingRunRequestFactory } from "@/store/modules/API/modules/topicModeling";

export default {
  name: "SettingsContent",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: topicModelingRunRequestFactory(),
      stopWord: "",
      numberRules_1: [
        v => v.length > 0 || this.$t("VALIDATION.COMMON.FIELD_REQUIRED"),
        v =>
          Number.isInteger(Number(v)) ||
          this.$t("VALIDATION.COMMON.VALUE_INTEGER"),
        v => v > 0 || this.$t("VALIDATION.COMMON.GREATER_ZERO"),
        v => v <= 10 || this.$t("VALIDATION.COMMON.MAX_10")
      ],
      numberRules_2: [
        v => v.length > 0 || this.$t("VALIDATION.COMMON.FIELD_REQUIRED"),
        v =>
          Number.isInteger(Number(v)) ||
          this.$t("VALIDATION.COMMON.VALUE_INTEGER"),
        v => v > 0 || this.$t("VALIDATION.COMMON.GREATER_ZERO"),
        v => v <= 50 || this.$t("VALIDATION.COMMON.MAX_50")
      ],
      numberRules_3: [
        v => v.length > 0 || this.$t("VALIDATION.COMMON.FIELD_REQUIRED"),
        v =>
          Number.isInteger(Number(v)) ||
          this.$t("VALIDATION.COMMON.VALUE_INTEGER"),
        v => v > 0 || this.$t("VALIDATION.COMMON.GREATER_ZERO"),
        v => v <= 20 || this.$t("VALIDATION.COMMON.MAX_20")
      ]
    };
  },
  computed: {
    ...mapGetters({
      runRequestLastParams: "api/topicModeling/getRunRequestLastParams",
      countryDictionary: "languageAudit/getCountryDictionary",
      user: "auth/getUser"
    })
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(data) {
        this.$emit("update-data", data);
      }
    },
    runRequestLastParams: {
      deep: true,
      immediate: true,
      handler(data) {
        this.form = clone(data);
      }
    }
  },
  methods: {
    addStopWord() {
      if (this.stopWord.length < 1) {
        return;
      }
      this.form.customStopwords.push(this.stopWord);
      this.stopWord = "";
    },
    removeStopWord(idx) {
      this.form.customStopwords.splice(idx, 1);
    }
  }
};
</script>

<style lang="scss">
.chips-list {
  .v-chip {
    margin: 0 5px 10px 5px;

    button {
      margin-bottom: 7px;
    }
  }
}
.dark-theme {
  background-color: rgba(62, 72, 92, 1);
  border-radius: 6px;

  .text--white {
    color: white;
  }
}
</style>
