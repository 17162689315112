<template>
  <v-container class="data-preview white text-center">
    <v-progress-linear indeterminate v-if="isLoading" />
    <table-language-audit @switch-to-import="isFilePresent = false" />
  </v-container>
</template>

<script>
import TableLanguageAudit from "@/views/LanguageAudit/components/TableLanguageAudit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Index",
  components: { TableLanguageAudit },
  data() {
    return {
      isFilePresent: false,
      isLoading: false
    };
  },
  created() {
    this.fillCountryDictionary();
  },
  computed: {
    ...mapGetters({
      countryDictionaryLength: "languageAudit/getCountryDictionaryLength"
    })
  },
  methods: {
    ...mapActions({
      getCountryDictionary: "languageAudit/requestCountryDictionary"
    }),
    async fillCountryDictionary() {
      if (this.countryDictionaryLength < 1) {
        await this.getCountryDictionary();
      }
    }
  }
};
</script>
<style lang="scss">
.data-preview {
  border-radius: 6px;
  max-width: 1280px;
}
</style>
