<template>
  <v-container class="table-language-container">
    <v-row>
      <v-col class="col-8">
        <v-pagination class="mb-4" v-model="page" :length="numberOfPages" />
        <v-data-table
          :headers="topics"
          :items="wordsRows"
          :items-per-page="items.length"
          :loading="isLoading"
          class="elevation-0"
          disable-sort
          hide-default-footer
        >
          <template v-slot:item="props">
            <tr>
              <td
                @mouseenter="handleMouseEnter($event, item)"
                @mouseleave="handleMouseLeave"
                class="words-td"
                v-bind:key="item.name + index"
                v-for="(item, index) in props.item"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="copy"
                      absolute
                      icon
                      right
                      top
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("COMMON.TOOLTIP.COPY_CONTENT") }}</span>
                </v-tooltip>
                <span>
                  {{ item.text }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider />
        <v-data-table
          :items="docsRows"
          :items-per-page="items.length"
          class="elevation-0"
          disable-sort
          hide-default-header
          hide-default-footer
        >
          <template v-slot:item="props">
            <tr>
              <td
                :key="item.name + index"
                @mouseenter="handleMouseEnter($event, item)"
                @mouseleave="handleMouseLeave"
                class="docs-td pa-2"
                v-for="(item, index) in props.item"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="copy"
                      absolute
                      icon
                      right
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("COMMON.TOOLTIP.COPY_CONTENT") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="openItemModal(item)"
                      absolute
                      icon
                      right
                      style="margin-right: 42px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("COMMON.TOOLTIP.OPEN_MODAL") }}</span>
                </v-tooltip>
                <span class="clamp-div">
                  {{ item.text }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <input id="simple-clipboard-holder" type="text" hidden />
        <v-pagination class="mt-4" v-model="page" :length="numberOfPages" />
      </v-col>
      <v-col class="col-4">
        <div class="container-border pb-8">
          <v-form class="settings" ref="form" @submit.prevent="handleSubmit">
            <settings-content
              @update-data="updateData"
              :is-loading="isLoading"
            />
            <v-btn
              type="submit"
              color="primary"
              class="mr-3 mt-2"
              outlined
              :disabled="isLoading"
              :loading="isLoading"
              >{{ $t("COMMON.SETTINGS.APPLY") }}</v-btn
            >
          </v-form>
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-3" />

    <v-row class="mt-2">
      <v-col class="text-right">
        <v-btn
          :disabled="isLoading"
          outlined
          color="action"
          @click="handleNewUpload"
          >{{ $t("FORM.BUTTON.IMPORT_NEW_CONTENT_FILE") }}</v-btn
        >
        <v-btn
          class="ml-4"
          :disabled="isLoading"
          outlined
          color="error"
          @click="handleCloseLanguageAudit"
          >{{ $t("FORM.BUTTON.CLOSE_LANGUAGE_AUDIT") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { copyTextToClipboard } from "@/helpers/helpers";
import SettingsContent from "./SettingsContent";
import { mapActions, mapGetters } from "vuex";
import { topicModelingRunRequestFactory } from "@/store/modules/API/modules/topicModeling";
export default {
  name: "TableLanguageAudit",
  components: { SettingsContent },
  data() {
    return {
      editedTopics: [],
      isLoading: false,
      columnsPerPage: 3,
      copyContent: false,
      currentHoveredItem: null,
      dialog: false,
      words: {},
      docs: {},
      page: 1
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
    numberOfPages() {
      return Math.ceil(this.allTopics.length / this.columnsPerPage);
    },
    allTopics() {
      return Object.keys(this.words).map(topic => ({
        align: "center",
        class: "brands-header",
        text: topic,
        value: topic
      }));
    },
    topics() {
      let start = (this.page - 1) * this.columnsPerPage;
      return this.allTopics.slice(start, start + this.columnsPerPage);
    },
    rowsWordsLength() {
      const firstTopic = this.topics[0].value;
      return this.words[firstTopic].length;
    },
    rowsDocsLength() {
      const firstTopic = this.topics[0].value;
      return this.docs[firstTopic].length;
    },
    wordsRows() {
      const items = [];
      const iterateThroughRows = () => {
        for (let row = 0; row < this.rowsWordsLength; row++) {
          const item = {};
          for (const { value: topicName } of this.topics) {
            item[topicName] = {
              text: this.words[topicName][row],
              name: topicName
            };
          }
          items.push(item);
        }
      };

      if (Object.keys(this.words).length > 0) {
        iterateThroughRows();
      }
      return items;
    },
    docsRows() {
      const items = [];
      const iterateThroughRows = () => {
        for (let row = 0; row < this.rowsDocsLength; row++) {
          const item = {};
          for (const { value: topicName } of this.topics) {
            item[topicName] = {
              text: this.docs[topicName][row],
              name: topicName
            };
          }
          items.push(item);
        }
      };
      if (Object.keys(this.docs).length > 0) {
        iterateThroughRows();
      }

      return items;
    },
    items() {
      return [...this.wordsRows, ...this.docsRows];
    }
  },
  mounted() {
    this.handleRunTopicModeling({
      data: {
        ...topicModelingRunRequestFactory(),
        dictionary: this.user.language
      }
    });
  },
  methods: {
    ...mapActions({
      runTopicModeling: "api/topicModeling/run"
    }),
    handleRunTopicModeling(params, redirectToImport = true) {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        this.runTopicModeling(params)
          .then(({ data }) => {
            resolve(data);
            this.page = 1;
            this.words = data.words;
            this.docs = data.docs;
            this.editedTopics = Object.keys(this.docs);
          })
          .catch(error => {
            reject(error);
            if (redirectToImport) {
              this.$router.push({ name: "LanguageAuditUpload" });
            }
            console.log({ error });
            this.$notifier.notify({
              message: error.response ? error.response.data.error : "Error",
              color: "error",
              timeout: 6000
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    copy() {
      copyTextToClipboard(
        this.currentHoveredItem.text,
        "simple-clipboard-holder"
      );
      this.$notifier.notify({
        message: this.$t("COMMON.NOTIFICATIONS.TEXT_COPIED")
      });
      setTimeout(() => {
        this.dialog = false;
      }, 500);
    },
    openItemModal(item) {
      this.$modals.informative({
        content: item.text,
        copyContent: true
      });
    },
    toggle(event) {
      event.target.classList.toggle("active");
    },
    handleMouseEnter(event, item) {
      this.currentHoveredItem = item;
      this.toggle(event);
    },
    handleMouseLeave(event) {
      this.currentHoveredItem = null;
      this.toggle(event);
    },
    handleNewUpload() {
      const action = () => {
        this.$emit("switch-to-import");
        this.$router.push({ name: "LanguageAuditUpload" });
      };
      this.$modals.confirmation({ action });
    },
    handleCloseLanguageAudit() {
      const action = () => {
        this.$router.push({ name: "Home" });
      };
      this.$modals.confirmation({ action });
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.handleRunTopicModeling(
          {
            data: this.data
          },
          false
        ).finally(() => {
          this.isLoading = false;
        });
      }
    },
    updateData(data) {
      this.data = data;
    }
  }
};
</script>

<style lang="scss">
.table-language-container {
  max-width: 1280px;
}
.brands-header {
  background-color: #3e485c;
  color: white !important;
}
.words-td,
.docs-td {
  position: relative;
  text-align: left;
  width: 33%;

  button {
    display: none;
  }
}
.active {
  button {
    background-color: white;
    display: block;
  }
}
.words-td {
  text-align: center;

  button {
    top: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
  }
}
.docs-td {
  vertical-align: top;
}
.clamp-div {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 10px;
  overflow: hidden;
}
.container-border {
  border: 2px solid #c8cfdd;
  border-radius: 8px;
  display: flex;
}
.settings {
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }
}
</style>
