var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"table-language-container"},[_c('v-row',[_c('v-col',{staticClass:"col-8"},[_c('v-pagination',{staticClass:"mb-4",attrs:{"length":_vm.numberOfPages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.topics,"items":_vm.wordsRows,"items-per-page":_vm.items.length,"loading":_vm.isLoading,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',_vm._l((props.item),function(item,index){return _c('td',{key:item.name + index,staticClass:"words-td",on:{"mouseenter":function($event){return _vm.handleMouseEnter($event, item)},"mouseleave":_vm.handleMouseLeave}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","icon":"","right":"","top":""},on:{"click":_vm.copy}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.TOOLTIP.COPY_CONTENT")))])]),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)]}}])}),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.docsRows,"items-per-page":_vm.items.length,"disable-sort":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',_vm._l((props.item),function(item,index){return _c('td',{key:item.name + index,staticClass:"docs-td pa-2",on:{"mouseenter":function($event){return _vm.handleMouseEnter($event, item)},"mouseleave":_vm.handleMouseLeave}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","icon":"","right":""},on:{"click":_vm.copy}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.TOOLTIP.COPY_CONTENT")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"42px"},attrs:{"absolute":"","icon":"","right":""},on:{"click":function($event){return _vm.openItemModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.TOOLTIP.OPEN_MODAL")))])]),_c('span',{staticClass:"clamp-div"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)]}}])}),_c('input',{attrs:{"id":"simple-clipboard-holder","type":"text","hidden":""}}),_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.numberOfPages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{staticClass:"col-4"},[_c('div',{staticClass:"container-border pb-8"},[_c('v-form',{ref:"form",staticClass:"settings",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('settings-content',{attrs:{"is-loading":_vm.isLoading},on:{"update-data":_vm.updateData}}),_c('v-btn',{staticClass:"mr-3 mt-2",attrs:{"type":"submit","color":"primary","outlined":"","disabled":_vm.isLoading,"loading":_vm.isLoading}},[_vm._v(_vm._s(_vm.$t("COMMON.SETTINGS.APPLY")))])],1)],1)])],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"disabled":_vm.isLoading,"outlined":"","color":"action"},on:{"click":_vm.handleNewUpload}},[_vm._v(_vm._s(_vm.$t("FORM.BUTTON.IMPORT_NEW_CONTENT_FILE")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":_vm.isLoading,"outlined":"","color":"error"},on:{"click":_vm.handleCloseLanguageAudit}},[_vm._v(_vm._s(_vm.$t("FORM.BUTTON.CLOSE_LANGUAGE_AUDIT")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }